ul.tree, ul.tree-child {
  list-style: none;
  padding: 0;
  margin: 0;
}
.tree-node {
  &.selected {
    > a.tree-title {
      background-color: #6E91AD;
    }
  }

  .tree-title {
    width: 100%;
    display: block;
    color: inherit;
    padding: 8px;
    border-bottom: solid 1px #949494;
  }

  a.tree-title:hover {
    color: inherit;
    text-decoration: none;
    background-color: #888888;
  }
}