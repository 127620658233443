@import "custom_bootstrap";
@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'node_modules/bootstrap-vue/src/index.scss';
@import "tree";

/**
 * App固有のスタイル設定
 */
#app {
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 100px;/*←footerの高さ*/
  box-sizing: border-box;/*←全て含めてmin-height:100vhに*/
  position: relative;
}
.container-fluid {
  padding: 1rem;
}
canvas {
  border: solid 2px #000;
  margin-top: -2px;  // border分だけ上にあげる
  width: 100% !important;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-4	{
  padding-left: 1rem;
  padding-right: 1rem;
}

.grow {
  flex-grow:1;
}

.border-b-3 {
  border-bottom-width: 3px;
}

.border-solid	{
  border-style: solid;
}

.border-dark-grey {
  border-color: #676767
}

.border-x-0 {
  border-left-width: 0px;
  border-right-width: 0px;
}

.border-t-0 {
  border-top-width: 0px;
}

.text-xs {
    font-size: 0.75rem; /* 12px */
    line-height: 1rem; /* 16px */
}

/* 共通設定 */
.modal-open .modal {
  overflow: visible;
}
.modal-body {
  max-height: 80vh;
  overflow-y: auto;
}
.modal-notice-text {
  height: 80%;
  width: 80%;
}
#terms-of-service .modal-dialog,
#terms-of-service-footer .modal-dialog,
#privacy-policy-footer .modal-dialog {
  height: 90%;
}
#terms-of-service .modal-content,
#terms-of-service-footer .modal-content,
#privacy-policy-footer .modal-content {
  height: 100%;
  overflow-y: scroll;
}

.terms-of-service-text {
  line-height: 1.5;

  p {
    margin-top: 1em;
  }

  ol {
    list-style-type: none;
    counter-reset: lv1-counter;

    li::before {
      content: counter(lv1-counter);
      counter-increment: lv1-counter;
      margin-right: 1.3em;
      margin-left: -1.8em;
    }
    li {
      padding-left: 2.3em;

      ol {
        list-style-type: none;
        counter-reset: lv2-counter;

        li::before {
          content: counter(lv2-counter);
          counter-increment: lv2-counter;
          font-size: 85%;
          margin-right: 1.3em;
          margin-left: -2.8em;
          padding: 0 0.4em 0.08em;
          line-height: 1.75;
          border: #000 1px solid;
          border-radius: 50% 50%;
        }
        li:nth-child(n+10)::before {
          padding: 0 0.11em 0.08em;
        }

        li {
          padding-left: 2.38em;
        }
      }
    }
  }
}

.privacy-policy-text {
  line-height: 1.5;

  p {
    margin-top: 1em;
  }

  ol {
    list-style-type: decimal;

    li {
      /* 必要なスタイル設定を記述 */
    }

    ol.circled {
      list-style-type: none;
      counter-reset: lv2-counter;

      li::before {
        content: counter(lv2-counter);
        counter-increment: lv2-counter;
        font-size: 85%;
        margin-right: 1.3em;
        margin-left: -2.8em;
        padding: 0 0.4em 0.08em;
        line-height: 1.75;
        border: #000 1px solid;
        border-radius: 50% 50%;
      }
      li:nth-child(n+10)::before {
        padding: 0 0.11em 0.08em;
      }

      li {
        padding-left: 2.38em;
      }
    }

    ol.lower-alpha {
      list-style-type: lower-alpha;

      li {
        /* 必要なスタイル設定を記述 */
      }
    }

    ul {
      list-style-type: none;

      li {
        text-indent: -1.3em;
      }
    }
  }
}

/* MENU 用スタイル設定 */
#menu {
  font-weight: bold;

  .btn {
    border-radius: 50% 50%;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    & > img {
      height: 30px;
      margin: auto auto;
      filter: invert(100%) brightness(300%);
    }
  }

  .menu-title {
    font-size: 200%;
    font-weight: 700;
  }

  .menu-caption {
    font-size: 120%;
  }

  .footer-image {
    width: 100%;
    margin: 0 0 55px;
    position: absolute;
    left: 0;
    bottom: 0;

    & img {
      width: 100%;
      height: auto;
    }
  }
}
/* ヘッダ下の幅いっぱいの帯部分 */
#full-width-section {
  width: auto;
  margin: -16px -16px 0;
  padding: 20px 30px 20px 20px;
  background-color: #000000;

  .text-floating-left {
    float: left;
    margin-top: 7px;
    line-height: 40px;
  }

  .btn {
    line-height: 40px;
  }

  .btn-2rows {
    line-height: 20px;
  }

  /* 進捗図のスタイル：STEP1～5 の各画面より移動 */
  .stepFlow {
    font-size: 80%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .stepFlow > li {
    position: relative;
    display: block;
    width: auto;
    min-width: 115px;
    margin: 0;
    padding: 0;
    list-style: none;
    text-overflow: ellipsis;
    color: #b0bec5;
    -ms-flex: 1;
    -moz-flex: 1;
    -webkit-box-flex: 1;
        flex: 1;
  }
  .stepFlow > li .title {
    font-weight: bold;
    width: 150px;
    margin-top: 0.7rem;
    margin-bottom: 0.1rem;
  }
  .stepFlow > li .text {
    display: block;
    padding-right: 2.5em;
  }
  .stepFlow > li .bubble {
    display: block;
    width: 20px;
    height: 20px;
    margin: 0 0 0.2em;
    border-radius: 1000px;
    background-color: #b0bec5;
  }
  .stepFlow > li .bubble::after,
  .stepFlow > li .bubble::before {
    position: absolute;
    top: 9px;
    display: block;
    width: 100%;
    height: 3px;
    content: '';
    background-color: #b0bec5;
  }
  .stepFlow > li.completed > .text,
  .stepFlow > li.completed > .title {
    color: #b0bec5;
  }
  .stepFlow > li.completed,
  .stepFlow > li.completed .bubble {
    color: #00acc1;
  }
  .stepFlow > li.completed .bubble,
  .stepFlow > li.completed .bubble::after,
  .stepFlow > li.completed .bubble::before {
    background-color: #b0bec5;
  }
  .stepFlow > li:last-child .bubble::after,
  .stepFlow > li:last-child .bubble::before {
    width: 0%;
    margin-right: 50%;
  }
  .stepFlow > li.active,
  .stepFlow > li.active .bubble {
    color: #fcb502;
  }
  .stepFlow > li.active .bubble,
  .stepFlow > li.active .bubble::after,
  .stepFlow > li.active .bubble::before {
    background-color: #fcb502;
  }
}

#kagechi-upload::before {
  content: "";
  display: block;
  height: 100px;
}

#kagechi-upload {
  h5 {
    margin-bottom: 15px;
  }
  .header-top-green {
    border-left: solid 6px $header-top-green;
    padding-left: 10px;
  }
  .upload-icon {
    filter: brightness(170%);  /* アイコン画像の色を明るくしたかったが、画像編集では画質が荒れたのでcssにて明度を上げるようにした */
  }
  .list-group {
    height: 400px;
    overflow: auto;
    border-radius: none;
    background-color: #434343;
    background-image: 
      linear-gradient(to right, $border-color 50%, rgba(255,255,255,0) 0%),
      linear-gradient(to bottom, $border-color 50%, rgba(255,255,255,0) 0%),
      linear-gradient(to left, $border-color 50%, rgba(255,255,255,0) 0%),
      linear-gradient(to top, $border-color 50%, rgba(255,255,255,0) 0%);
    background-position:
      left top,
      right top,
      right bottom,
      left bottom;
    background-size:
      10px 1px,
      1px 10px,
      10px 1px,
      1px 10px;
    background-repeat:
      repeat-x,
      repeat-y,
      repeat-x,
      repeat-y;

    &.dragging {
      background-color: $gray-600;

      .list-group-item {
        background-color: $gray-600;
      }
    }

    .list-group-item {
      background-color: #434343;
      padding: 0.25rem 1.25rem;
      margin: 2px 2px 0 2px;

      &.empty {
        height: 50%;
        text-align: center;
        border-bottom: none;

        .top-spacing {
          margin-top: 50px;
        }
      }

      .btn {
        color: $danger;
      }
    }
  }
}

#kagechi-list {
  thead {
    text-align: center;

    th {
      border-top: none;
      top: -2px !important;
    }
  }

  tbody {
    background-color: #949494;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: $red;
    border-color: $red;
    background-color: $red;
  }
}

.modal-dialog.pdf-image {
  width: 90%;
  max-width: initial;
}

/**
 * darkモード用スタイル設定
 */
#app.dark {
  background-color: #333333;
  color: #fcfcfc;

  /* Bootstwrap のスタイル上書き */
  .btn-outline-secondary {
    color: #ffffff;
  }
  
  .nav-tabs {
    color: #7b7b7b;
    border-bottom: none !important;
  }

  .nav-tabs:empty {
    background: #ff0000;
  }

  .nav-tabs .nav-link {
    border: none;
    border-bottom: 3px solid #676767;
    margin-bottom: 0;
  }
  
  .nav-tabs .nav-link,
  .nav-tabs .nav-link.active {
    background-color: #333333;
    color: #ffffff;
  }
  
  .nav-tabs .nav-link.active {
    border-bottom: 3px solid #fcb502;
  }

  .card {
    background-color: #434343;
    color: #ffffff;
    border-radius: 0;
    border: none;
    border-top: #333333;
  }

  .input-group > .custom-file,
  .input-group > .custom-select,
  .input-group > .form-control,
  .input-group > .form-control-plaintext {
    color: #ffffff;
    background-color: #676767;
    border: 1px solid #878787;
    border-radius: 2px;
  }
  .input-group > .custom-select {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23ffffff' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
  }
  .custom-radio .custom-control-label::before {
    color: #ffffff;
    background-color: #676767;
    border: 1px solid #878787;
  }
  .custom-control-label::before {
    color: #ffffff;
    background-color: #676767;
    border: 1px solid #878787;
  }

  /* STEP1～5 のスタイル設定 */
  .operator-box {
    padding: 4px 8px;
    border: 2px solid #434343;
    background-color: #434343;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;

    .btn-outline-light {
      border: none;
    }

  }

  .plat-box {
    height: 600px;
    border: solid 1px #333333;
    margin-top: -2px;  // border分だけ上にあげる
    overflow: auto;

    .header-top-green {
      border-left: solid 3px $header-top-green;
      padding-left: 10px;
    }

    .text-light-gray {
      color: #7b7b7b;
    }

    .text-white,
    .card-text {
      color: #ffffff;
    }

    /* STEP1 のトグルスイッチ */
    .toggle-switch,
    .toggle-switch:hover,
    .toggle-switch:focus {
      height: 26px;
      width: 50px;
      background-color: #434343;
      border: none;
      position: relative;
    }
    .toggle-switch::before {
      content: '';
      height: 10px;
      width: 50px;
      display: block;
      background-color: #676767;
      border: none;
      border-radius: 5px;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 100;
      margin-top: -5px;
      transition: all 0.3s ease-in-out;
    }
    .toggle-switch::after {
      content: '';
      height: 26px;
      width: 26px;
      display:block;
      background-color: #7b7b7b;
      border: none;
      border-radius: 50% 50%;
      position: absolute;
      top: 50%;
      z-index: 101;
      margin-top: -13px;
      transition: all 0.3s ease-in-out;
    }

    .toggle-switch.btn-secondary,
    .toggle-switch.btn-secondary:hover,
    .toggle-switch.btn-secondary:focus,
    .toggle-switch.btn-secondary:active,
    .toggle-switch.btn-info,
    .toggle-switch.btn-info:hover,
    .toggle-switch.btn-info:focus,
    .toggle-switch.btn-info:active {
      background-color: #434343;
      border: none;
      box-shadow: none;
    }
    .btn-secondary.toggle-switch::after {
      /* スライダが左 */
      left: 0;
    }
    .btn-info.toggle-switch::before {
      background-color: #c5e2e3;
    }
    .btn-info.toggle-switch::after {
      /* スライダが右 */
      right: 0;
      background-color: #5e9ea0;
    }
  }

  .section-panel {
    color: #ffffff;
    background-color: #434343;
    border: none;
    margin-bottom: 2px;
  }

  /* STEP3 固有のスタイル設定 */
  #toolbar .btn img,
  .layer-icon > .color-invert {
    filter: invert(100%) brightness(300%); /* 画像アイコンを黒→白にする */
  }
  #toolbar .btn {
    border-radius: 0;
  }

  .btn-colorselect {
    border-radius: 0;
    padding: 4px;
  }
  .btn-colorselect .btn-inner{
    height: 24px;
    width: 24px;
  }

  #layer-list .list-group-item {
    color: #ffffff;
    background-color: #676767;
    border: 1px solid #878787;
    border-radius: 0;
  }
  #layer-list .btn-dark,
  #layer-list .btn-dark:focus {
    background-color: #676767;
    border: none;
  }

  .layer-caption {
    position: absolute;
    top: -25px;
    left: 110px;
  }
}

/* STEP1～5 共通のスタイル */
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.float-left::after,
.float-right::after {
  clear: both;
}

.pos-right,
.pos-bottom {
  position: absolute;
}
.pos-right {
  right: 0;
}
.pos-bottom {
  bottom: 0;
}

.btn-no-border,
.btn-no-border:focus {
  border: none;
  box-shadow: none;
}

@media (min-width: 576px) {
  div[id^="addVtx"] .modal-dialog,
  div[id^="drawingCondition"] .modal-dialog,
  div[id^="addRoad"] .modal-dialog,
  div[id^="setback-modal-"] .modal-dialog,
  div[id^="route-price-"] .modal-dialog {
    margin-right: 1.75rem;
  }
}
/* フリーミアムアイコンのスタイル */
.icon-btn {
  position: relative;
}
.freemium-icon {
  border-radius: 50% 50%;
  display: inline-block;
  background-color: #5e9ea0;
  color:#ffffff;
  padding: 2px;
}
