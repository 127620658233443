


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.input-group {
  & > .form-control, & > .custom-select {
    border-radius: 0.25rem !important;
  }
}
.input-group-text {
  background-color: transparent;
  border: none;
  color: white;
}
.operator-box {
  padding: 4px 8px;
  border: solid 2px #000;
  background-color: #eee;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
}
