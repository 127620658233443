































































































input[type="text"] {
  text-align: center;
  font-size: 120%;
}
